module.exports = (function(){
    return {
        JSON_LOADED : 'json_loaded',
        MODEL_CHANGED : 'model_changed',
        HIDE_FOOTER : 'hide_footer',
        SHOW_FOOTER : 'show_footer',
        SET_LANG : 'set_lang',
        LANG_CHANGED : 'lang_changed',
        SEARCH: 'search',
        CLEAR_SEARCH: 'clear_search',
        GRAB_FEED: 'grab_feed',
        HOME_MOUNTED: 'home_mounted'
    };
})();