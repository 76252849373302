'use strict'

import Backbone from 'backbone'
import Events from './events/events.js'
import _ from 'lodash'
import $ from 'jquery'

export default class Model extends Backbone.Model {

	initialize( options ) {
		this.dispatch = options.dispatch
		this.dispatch.on( Events.SEARCH, this._search, this )
		this.dispatch.on( Events.CLEAR_SEARCH, this._clearSearch, this )
		this.dispatch.on( Events.GRAB_FEED, this._grabFeed, this )
	}

	setData() {
		if (window.pageData) {
			this.attributes.page = window.pageData.page
			this.attributes.data = window.pageData.data

			if ( window.pageData.page === 'single-product' && window.pageData.data.video != '') {
				this.attributes.data.gallery.push({ video: window.pageData.data.video, thumb: '/wp-content/themes/lirene-pl/library/images/video-thumb.png' })
			}
		}
	}

	_search( data ) {
		let s = '/'+data.lang+'/search'
		if (data.lang === 'pl') {
			s = '/search'
		}
		$.ajax({ url: '/wp-json/search/v1'+s+'?s='+encodeURI(data.val), success: this._searchResults, context: this })
	}

	_clearSearch() {
		this.attributes.searchData = []
		this._update()
	}

	_searchResults( data ) {
		this.attributes.searchData = data.results
		if (data.results === null) {
			this.attributes.searchData = []
		}
		this._update()
	}

	_feedResults( data ) {
		this.attributes.feed = data
		this._update()
	}

	_update() {
		this.dispatch.trigger( Events.MODEL_CHANGED )
	}

	defaults() {
		return { 	
			searchData: [],
			feed: []
		}
	}
}