/* Component for easier ES6 binding */

'use strict';

import React from 'react';

export default class BaseComponent extends React.Component {

	// The rest parameter syntax allows to represent an indefinite number of arguments as an array.
	// https://developer.mozilla.org/pl/docs/Web/JavaScript/Reference/Functions/rest_parameters
	_bind(...methods) {
		methods.forEach( (method) => this[method] = this[method].bind(this) );
	}

	setContent(id) {
		this.setState({ index : id })
	}

	replaceSpecialChars(string) {
		return string.replace(/&#038;/g, "&").replace(/&amp;/g, "&").replace(/&#8211;/g, "-").replace(/proBTS3/g, "proBTS<sup>3</sup>").replace(/Wit. Dpro/g, "Wit.&nbspDpro").replace(/Dpro/g, "D<sub>pro</sub>").replace(/ i /g, " i&nbsp;");
	}
	// var t0, t1;
	// function orphanator() {
	//   var $html = $('p').html();
	//   t0 = performance.now();
	//   $html = $html.replace(/(\s)([\S])[\s]([\S])[\s]+/g, "$1$2&nbsp;$3&nbsp;");
	//   $html = $html.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;");
	//   t1 = performance.now();
	//   $('.after').html($html);
	// }

	// orphanator();
	// $('.performance').html("Call to doSomething took " + (t1 - t0) + " milliseconds.")
}