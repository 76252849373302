'use strict'

import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import Events from '../events/Events.js'
import BaseComponent from './BaseComponent.jsx'
import SliderItem from './SliderItem.jsx'
import TransitionGroup from 'react-addons-css-transition-group'
import Hammer from 'react-hammerjs'

export default class Slider extends BaseComponent {

	constructor(props) {
		super(props)
		this.dispatch = this.props.dispatch
		this.state = { sliderIndex: 0, 
									 videoSlider: false, 
									 mounted: false ,
								 }
		this._bind('slideTo', 'handleSwipe')
	}

	componentDidMount() {
		setTimeout(()=>{
			this.setState({ mounted: true })
			this.dispatch.trigger( Events.HOME_MOUNTED )
		}, 100)

		this.startSlider()
	}

	startSlider() {
		//console.log('////start Slider')
		this.sliderTimeout = setTimeout(()=>{
			if (this.state.sliderIndex < this.props.data[this.props.sliderName+'_slider'].length-1) {
				this.moveItems(1);
			} else {
				this.slideTo(0);
			}
		}, 15000)
	}

	stopSlider() {
		clearTimeout(this.sliderTimeout)
	}

	slideTo(id) {
		this.setState({ sliderIndex: id })
		this.stopSlider()
		this.startSlider()
		if( this.state.videoSlider ) {
			this.videoOff()
		}
	}

	moveItems(delta) {
		const max = this.props.data[this.props.sliderName+'_slider'].length-1;
    if (delta > 0) { 
    	if ( this.state.sliderIndex < max ) {
				this.slideTo(this.state.sliderIndex + 1);
    	} else {
    		this.slideTo(0);
    	}
    } else if (delta < 0) {
    	if ( this.state.sliderIndex > 0 ) {
	      this.slideTo(this.state.sliderIndex - 1);
	    } else {
	    	this.slideTo(max);
	    }
    }
  }

  handleSwipe(e) {
    let index = this.state.sliderIndex
    if (e.direction === 4) {
      this.moveItems(-1)
    } else if (e.direction === 2) {
      this.moveItems(1)
    }
  }

	render() {
		let insta
		let animation = 'slide-home'
		const sliderData = this.props.data[this.props.sliderName+'_slider']
		const index = this.state.sliderIndex
		let expandedStyle = {}

		let sliderNav = sliderData.map((item, key) => {
			return (<li className={index === key ? 'active' : ''} key={'nav-item-'+key} onClick={ this.slideTo.bind(this, key) }>{ item.title }</li>)
		})

		let unmounted = this.state.mounted ? "": "unmounted"

		return (
			<div className={this.props.sliderName+'-slider slider-holder'}>
				<div className="prev" onClick={()=>{this.moveItems(-1)}}></div>
				{/*<ul>
					{ sliderNav }
				</ul>*/}
				<div className="next" onClick={()=>{this.moveItems(1)}}></div>
				<Hammer onSwipe={this.handleSwipe} direction="DIRECTION_HORIZONTAL">
					<TransitionGroup 
					 	component="div" 
					 	className={ this.state.videoSlider ? "slider expanded "+unmounted : "slider "+unmounted }
					 	style={ expandedStyle }
					 	transitionName={ animation } 
					 	transitionAppear={true}
	      		transitionAppearTimeout={1800}
						transitionEnterTimeout={1000} 
						transitionLeaveTimeout={1000} >
					 	<SliderItem 
					 		index={ index }
					 		key={ 'slide-item-'+index }
					 		playVideo={ this.videoOn }
							data={ sliderData[index] }
							screen={ this.props.screen }/>
					</TransitionGroup>
				</Hammer>
			</div>
		)
	}
}