'use strict'

import React from 'react'
import BaseComponent from './BaseComponent.jsx'

export default class SliderItem extends BaseComponent {

	constructor(props) {
		super(props)
		this.state = { playing: false }
		this._bind('playVideo')
	}

	playVideo(e) {
		e.preventDefault()
		this.props.playVideo()
	}

	render() {
		let video
		let bg = this.props.data.bg_src
		if( this.props.screen.width < 720) {
			bg = this.props.data.mobile_bg_src
		}

		let slideItem = (
			<div className={"slider-item slider-item-"+this.props.index}>
		 		<div className="slider-background" style={{ backgroundImage: 'url('+bg+')' }}></div>
		 	</div>
		)

		return (
			<div className="slider-item-wrap">
				{ slideItem }
			</div>
		)
	}
}