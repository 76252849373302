'use strict'

import React from 'react'
import ReactDOM from 'react-dom'
import $ from 'jquery'
import Backbone from 'backbone'
import _ from 'lodash'
import Model from './model.js'
import Events from './events/events.js'
import Slider from './components/Slider.jsx'
import Flickity from 'flickity';

export default class Main {

	constructor() {
		this.dispatch = _.extend({}, Backbone.Events )
		this.model = new Model({ dispatch : this.dispatch })
		this.model.setData()
		this.screen = { width: window.innerWidth, height: window.innerHeight }
		this.dispatch.on( Events.HOME_MOUNTED, this.animateHome, this )
		
		this.init()
		this.dispatch.on( Events.MODEL_CHANGED, this.render, this )
	}

	handleResize(e) {
		this.screen = { width: window.innerWidth, height: window.innerHeight }
		this.render()
	}

	init() {
		this.render()
		const scope = this;
		
		// mobile menu - move to react
		const header = $('.header');
	  	const trigger = $('.menu-toggle');
	  	const nav = $('.main-nav');

	  	trigger.on('click', function() {
	  		$('.header__nav .menu').toggleClass('menu--visible');
	  	})

	  /*for (var r = i(14), a = document.querySelectorAll(".intro__link"), h = document.getElementById("button"), l = 0; l < a.length; l++) a[l].addEventListener("click", s);*/
    var products = ["dentosept", "dentosept-fast", "dentosept-a", "dentosept-complex", "dentosept-a-mini"],
        selector = document.querySelector(".intro__products");
        postSlider = document.querySelector(".slider");

    if (selector) {
        var slider = new Flickity(selector, {
            cellSelector: ".intro__product",
            freeScroll: !1,
            wrapAround: !0,
            prevNextButtons: !0,
            setGallerySize: !1,
            pageDots: !1
        });
        slider.on("select", function() {
            document.body.className = products[slider.selectedIndex], h.href = products[slider.selectedIndex]
        })
    }

    if (postSlider) {
        var postSlider = new Flickity(postSlider, {
            cellSelector: ".featured-item",
            freeScroll: !1,
            wrapAround: true,
            prevNextButtons: false,
            setGallerySize: !1,
            pageDots: true
        });
    }

    // header scroll
    var r = document.getElementById("header"),
        o = 10;
    document.addEventListener("scroll", function() {
        var t = document.documentElement,
            e = (window.pageYOffset || t.scrollTop) - (t.clientTop || 0);
        e > o && r.className.indexOf("header--sticky") == -1 ? r.className += " header--sticky" : e <= o && (r.className = r.className.replace("header--sticky", "").trim())
    })
	}

	render() {
		let page = this.model.get('page');
		let home = undefined;
		let home2 = undefined;
		let posters = undefined;
		let toolkit = undefined;

		if( page ) {
			switch( page ) {
				case 'home':
					home = <Slider
									data={ this.model.get('data') }
									screen={ this.screen }
									dispatch={ this.dispatch }
									sliderName="home" />
					home2 = <Slider
									data={ this.model.get('data') }
									screen={ this.screen }
									dispatch={ this.dispatch }
									sliderName="home_second" />
					break;
			case 'posters':
					posters = <Slider
									data={ this.model.get('data') }
									screen={ this.screen }
									dispatch={ this.dispatch }
									sliderName="posters" />
					break;
			case 'toolkit':
					toolkit = <Slider
									data={ this.model.get('data') }
									screen={ this.screen }
									dispatch={ this.dispatch }
									sliderName="toolkit" />
					break;
			}

			if (home) {
				ReactDOM.render( home, document.getElementById('maths-home-slider') )
				ReactDOM.render( home2, document.getElementById('maths-home-second-slider') )
			}
			if (posters) {
				ReactDOM.render( posters, document.getElementById('maths-posters-slider') )
			}
			if (toolkit) {
				ReactDOM.render( toolkit, document.getElementById('maths-toolkit-slider') )
			}
		}
	}
}

const app = new Main()
